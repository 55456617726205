/*
 * @Description: 格式化
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-15 16:57:30
 * @LastEditors: PSG
 * @LastEditTime: 2021-04-16 18:23:26
 */
export function toPercent (point) {
  let str = (Number(point) * 100).toFixed(2)
  str += '%'
  return str
}