<!--
 * @Description: 结果分析-矩阵量表
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-12 11:55:01
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-05 15:34:58
-->
<template>
  <div class="matrix-scale-analysis">
    <h1>{{question.sort + 1}}.<RichTitle :title="question.title"/></h1>
    <div class="content">
      <div class="colunm">
        <span>行标题\列标题</span>
        <span
          v-for="(colItem, colIndex) in question.columnItems"
          :key="colIndex"
        >{{colItem.text}}</span>
        <span>平均分</span>
      </div>
      <div 
        class="row"
        v-for="(rowItem, rowIndex) in question.rowItems"
        :key="rowIndex"
      >
        <span>{{rowItem.text}}</span>
        <span
          v-for="(colItem, colIndex) in question.columnItems"
          :key="colIndex"
        >
          {{question.analysis.table[rowIndex][colIndex].nums}}
          &nbsp;
          ({{toPercent(question.analysis.table[rowIndex][colIndex].percents)}})
        </span>
        <span>
          {{question.analysis.rowAverageScores[rowIndex].toFixed(2)}}
        </span>
      </div>
      <div class="row">
        <span class="normal">合计</span>
        <span
          v-for="(colItem, colIndex) in question.columnItems"
          :key="colIndex"
        >
          {{item.analysis.table[item.rowItems.length][colIndex]['nums']}}
        </span>
        <span>
          {{(item.analysis.rowAverageScores[item.rowItems.length]).toFixed(2)}}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import RichTitle from '@/components/question/components/richTitle'

import { toPercent } from '@/utils/format.js'
import { computed } from '@vue/runtime-core'

const constructData = (data) => {
  let question = data
  const analysis = question.analysis
  return question
}
export default {
  name: 'MatrixScaleAnalysis',
  components: {
    RichTitle
  },
  props: ['item'],
  setup (props) {
    const question = computed({
      get: () => constructData(props.item) 
    })
    return {
      question,
      toPercent,
    }
  }
}
</script>
<style lang="less" scoped>
.matrix-scale-analysis {
  margin-top: 32px;
  h1 {
    font-size: 16px;
    font-weight: bold;
    div, p {
      display: inline-block;
    }
  }
  .normal {
    font-weight: normal;
  }
  .content {
    padding-bottom: 1px;
    font-weight: 100;
    overflow-y: auto;
    .colunm {
      border: 1px solid #eff2f5;
      background-color: #f8f9fa;
      white-space:nowrap;
      display: inline-block; // 宽度根据内部元素的变化而变化
      min-width: 100%; // 如果儿div内孙div元素宽度少于父div宽度，那就让儿div宽度是父div宽度100%
      span {
        font-size: 14px;
        width: 150px;
        display: inline-block;
        align-self: center;
        text-align: center;
        padding: 14px 16px;
        font-weight: normal;
      }
    }
    .row {
      padding: 12px 0;
      white-space:nowrap;
      display: inline-block; // 宽度根据内部元素的变化而变化
      min-width: 100%; // 如果儿div内孙div元素宽度少于父div宽度，那就让儿div宽度是父div宽度100%
      &:hover {
        background-color: #eff2f5;
      }
      span {
        width: 150px;
        display: inline-block;
        text-align: center;
        line-height: 22px;
        padding: 0 16px;
        white-space: pre-wrap;
      }
    }
  }
}
</style>