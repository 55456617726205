<!--
 * @Description: 结果分析
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-12 11:38:24
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-27 15:00:20
-->
<template>
  <div v-if="isHasAnalytics" class="analysis">
    <div class="title">{{questionnaire.title}}</div>
    <component
      v-for="(item, index) in list"
      :is="item.componentName"
      :item="item"
      :index="index"
      :key="item.questionId"
      @showAnalysisDetailModal="handleShowAnalysisDetail"
    />
  </div>
  <AnalysisRemind v-else/>
  <AnalysisDetailModal
    v-if="isShowAnalysisDetailModal"
    :visible='visible'
    :questionId="questionId"
    :choiceId="choiceId"
    :questionSort="questionSort"
    :questionTitle="questionTitle"
    :choiceText="choiceText"
    @close="handleCloseModal"
    @openQuestionnaireWithAnswer="handleOpenQuestionnaireWithAnswer"
  />
  <QuestionnaireDetail
    v-if="isShowDetailModal"
    :questionnaireId="previewQuestionId"
    @close="handleCloseClick"
  /> 
</template>
<script>
import Part from './components/Part'
import CommonAnalysis from './components/CommonAnalysis'
import MatrixAnalysis from './components/MatrixAnalysis'
import MatrixScaleAnalysis from './components/MatrixScaleAnalysis'
import ShortOrDataAnalysis from './components/ShortOrDataAnalysis'
import AnalysisRemind from './components/AnalysisRemind'
import AnalysisDetailModal from '@/components/modal/AnalysisDetailModal'
import QuestionnaireDetail from '@/views/detail/recycleBin/answerDetails/components/QuestionnaireDetail'

import useCommonConstructEffect from '@/effects/constructEffect'
import globalQuestionnaire from '@/class/useQuestionnaire'


import { getQuestionnaireList, getReport } from '@/api/questionnaire'
import { useRoute, useRouter } from 'vue-router'
import { ref, watch, watchEffect } from 'vue'
import { message } from 'ant-design-vue'

/**
 * 获取问卷统计数据
 */
const getReportEffect = () => {
  const route = useRoute()
  const questionnaireId = route.params.id
  const questionnaire = globalQuestionnaire.getQuestionnaire()
  
  const list = ref([])
  const isHasAnalytics = ref(true)
  const { constructQuestWithAnalysis } = useCommonConstructEffect()
  getQuestBasicInfoById(questionnaireId)
  
  // 异步获取问卷基础信息
  async function getQuestBasicInfoById (id) {
    const result = await getQuestionnaireList({id})
    if (result.status === 200 && result.data?.code === '00000' && result?.data?.data) {
      const qbasicInfo = result.data.data.content[0]
      if (qbasicInfo.validQuestionnaireNum > 0) {
        getReportById(id)
      } else {
        isHasAnalytics.value = false // 显示无统计数据页面
      }
    } else {
      message.error(' 请求问卷基本信息失败，失败原因：' + result.data.msg)
    }
  }
  
  // 异步获取问卷统计信息
  async function getReportById  (id)  {
    const result = await getReport(id)
    if (result.status === 200 && result.data?.code === '00000' && result?.data?.data) {
      const data = result.data.data.questionnaireProtoInfo
      data.questions = result.data.data.questions
      data.analytics = result.data.data.analytics
      globalQuestionnaire.setQuestionnaire(constructQuestWithAnalysis(data))
    } else {
      message.error(' 请求问卷统计信息失败，失败原因：' + result.data.msg)
    }
  }
  
  watchEffect(() => {
    list.value = []
    let questions = questionnaire.questions || []
    for (let i = 0; i < questions.length; i++) {
      if (!/(page)/.test(questions[i].questionType.toLowerCase())) {
        list.value.push(questions[i])
      }
    }
  })
  return {
    questionnaire,
    list,
    isHasAnalytics,
  }
}

/**
 * 处理订阅相关事件
 */
const handleSubscriptionEffect = () => {
  const isShowAnalysisDetailModal = ref(false)
  const isShowDetailModal = ref(false)
  const previewQuestionId = ref('')
  const visible = ref(true)
  const questionId = ref()
  const choiceId = ref()
  const questionSort = ref()
  const questionTitle = ref()
  const choiceText = ref()
  // 订阅关闭分析详情模态框
  const handleCloseModal = () => { 
    visible.value = false
    isShowAnalysisDetailModal.value = false
  }
  // 订阅显示分析详情模态框
  const handleShowAnalysisDetail = (qid, cid, sort, qTitle, cText) => {
    questionId.value = qid
    questionSort.value = sort
    choiceId.value = cid
    questionTitle.value = qTitle
    choiceText.value = cText
    visible.value = true
    isShowAnalysisDetailModal.value = true
  }
  // 订阅显示答卷详情模态窗
  const handleOpenQuestionnaireWithAnswer = (questionnaireId) => {
    isShowDetailModal.value = true
    previewQuestionId.value = questionnaireId
  }
  // 关闭答卷详情模态窗
  const handleCloseClick = () => {
    isShowDetailModal.value = false
  }
  return {
    questionId, 
    choiceId,
    questionSort,
    questionTitle,
    choiceText,
    isShowAnalysisDetailModal,
    visible,
    handleCloseModal,
    handleShowAnalysisDetail,
    isShowDetailModal,
    previewQuestionId,
    handleOpenQuestionnaireWithAnswer,
    handleCloseClick,
  }
}

export default {
  name: 'Analysis',
  components: {
    Part,
    CommonAnalysis,
    MatrixAnalysis,
    MatrixScaleAnalysis,
    ShortOrDataAnalysis,
    AnalysisRemind,
    AnalysisDetailModal,
    QuestionnaireDetail,
  },
  setup () {
    const { questionnaire, list, isHasAnalytics} = getReportEffect()
    const { questionId, choiceId, questionSort, questionTitle, choiceText, isShowAnalysisDetailModal, 
      visible, handleCloseModal, handleShowAnalysisDetail, isShowDetailModal, previewQuestionId, 
      handleOpenQuestionnaireWithAnswer, handleCloseClick } = handleSubscriptionEffect()
    return {
      questionnaire, 
      list,
      isHasAnalytics,
      
      questionId, 
      choiceId,
      questionSort,
      questionTitle,
      choiceText,
      isShowAnalysisDetailModal,
      visible,
      handleCloseModal,
      handleShowAnalysisDetail,
      isShowDetailModal,
      previewQuestionId,
      handleOpenQuestionnaireWithAnswer,
      handleCloseClick,
    }
  }
}
</script>
<style lang="less" scoped>
.analysis {
  width: 1300px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
  // position: absolute;
  // top: 120px;
  // left: 32px;
  // right: 32px;  
  // bottom: 32px;
  // overflow: auto;
  background: #fff;
  padding: 42px 60px;
  .title {
    font-size: 20px;
    height: 60px;
    text-align: center;
    border-bottom: 1px solid #e6e6e6;
  }
}
</style>
