<!--
 * @Description: 结果分析-单选题/多选题/下拉题/量表题
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-12 11:55:01
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-13 16:33:39
-->
<template>
  <div class="common-analysis" v-if="item">
    <h1>{{item.sort + 1}}.<RichTitle :title="item.title"/></h1>
    <div class="content">
      <div class="colunm">
        <span>选项</span>
        <span>数量</span>
        <span>百分比</span>
      </div>
      <div 
        class="row"
        v-for="(choice, choiceIndex) in item.choices"
      >
        <span>
          {{choice.text}}
          <span
            class="row-detail"
            v-if="choice.ifAllowFillInBlanks" 
            @click="handleDetailClick(item.questionId, choice.choiceId, item.sort, item.title, choice.text)"
          >详情</span>
        </span>
        <span>{{ item.analysis.numsPercents[choice.choiceId]['nums'] }}</span>
        <span>{{ toPercent(item.analysis.numsPercents[choice.choiceId]['percents']) }}</span>
      </div>
      <div class="row">
        <span class="normal">本次有效填写人数</span>
        <span>{{item.analysis.numberOfValidEntries}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import RichTitle from '@/components/question/components/richTitle'

import { toPercent } from '@/utils/format.js'

/**
 * emits相关事件
 */
const handleEmitsEffect = (emit) => {
  const handleDetailClick = (questionId, choiceId, sort, questionTitle, choiceText) => {
    emit('showAnalysisDetailModal', questionId, choiceId, sort, questionTitle, choiceText)
  }
  return {
    handleDetailClick
  }
}
export default {
  name: 'CommonAnalysis',
  components: {
    RichTitle
  },
  props: ['item'],
  emits: ['showAnalysisDetailModal'],
  setup (props, { emit }) {
    const { handleDetailClick } = handleEmitsEffect(emit)
    return {
      toPercent,
      handleDetailClick,
    }
  }
}
</script>
<style lang="less" scoped>
.common-analysis   {
  margin-top: 32px;
  h1 {
    font-size: 16px;
    font-weight: bold;
    div, p {
      display: inline-block;
    }
  }
  .normal {
    font-weight: normal;
  }
  .content {
    padding-bottom: 1px;
    font-weight: 100;
    overflow-y: auto;
    .colunm {
      border: 1px solid #eff2f5;
      background-color: #f8f9fa;
      span {
        font-size: 14px;
        width: 220px;
        display: inline-block;
        align-self: center;
        text-align: center;
        padding: 14px 16px;
        font-weight: normal;
      }
    }
    .row {
      padding: 12px 0;
      border-bottom: 1px solid #eff2f5;
      
      &:hover {
        background-color: #eff2f5;
      }
      span {
        width: 220px;
        display: inline-block;
        text-align: center;
        line-height: 22px;
        padding: 0 16px;
        white-space: pre-wrap;
      }
      .row-detail {
        width: auto;
        color: #f59e2f;
        text-decoration: underline;
        cursor:pointer;
      }
    }
  }
}
</style>