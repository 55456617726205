<!--
 * @Description: 结果分析-章节
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-12 11:52:56
 * @LastEditors: PSG
 * @LastEditTime: 2021-05-06 17:04:55
-->
<template>
  <div class="part">
    {{item.title}}
  </div>
</template>
<script>
export default {
  name: 'Part',
  props: ['item'],
  setup () {
    return {
      
    }
  }
}
</script>
<style lang="less" scoped>
.part {
  margin-top: 30px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #f8f8fa;
  font-size: 16px;
}
</style>