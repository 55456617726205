<!--
 * @Description: 结果分析-答卷详情弹出框
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-14 14:15:48
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-13 16:33:04
-->
<template>
  <div v-if="visible">
    <a-modal 
      v-model:visible="visible" 
      class="titleDescModal"
      :closable="false"
      width="900px"
    >
      <span class="iconfont close" @click="handleClose">&#xe61f;</span>
      <div class="title">{{questionSort + 1}}.<RichTitle :title="questionTitle"/></div>
      <div class="second-line">
        <div class="choice-text">{{choiceText}} 的文本答案</div>
        <div class="export" @click="handleExport">导出Execl</div>
      </div>
      <div class="content">
        <a-row class="table-title">
          <a-col :span="6" class="center">答卷编号</a-col>
          <a-col :span="6" class="center">提交答卷时间</a-col>
          <a-col :span="2"></a-col>
          <a-col :span="10">答卷文本</a-col>
        </a-row>
        <div class="content-wrapper">
          <a-row 
            class="table-row"
            v-for="(item, index) in list"
            :key="item.questionnaireId"
          >
            <a-col :span="6" class="center">
              <span 
                class="num"
              >
                {{item.numId}}
              </span>
            </a-col>
            <a-col :span="6" class="center">{{item.createTime}}</a-col>
            <a-col :span="2"></a-col>
            <a-col :span="10">{{item.content}}</a-col>
          </a-row>
        </div>
      </div>
      
      <Pager 
        class="page" 
        :current="current"
        :total="total"
        :size="size"
        shape="small"
        @onPageChange="onPageChangeEvent"
      />
    </a-modal>
  </div>
</template>
<script>
import Pager from '@/components/common/Pager'
import RichTitle from '@/components/question/components/richTitle'

import { useRoute, useRouter } from 'vue-router'
import { reactive, ref, watchEffect, toRefs } from 'vue'
import { getAnalysisDetail, exportAnalyzeQuestionnaireText } from '@/api/questionnaire'


/**
 * 获取单选、多选、日期、简答 填空答卷详情
 */
const getAnalysisDetailEffect = (props, protoId, pageNumber, pageSize) => {
  const analysis = reactive({
    list: [],
    current: 999,
    size: 5,
    total: 10
  })
  const getData = async (questionnaireProtoId, questionId, choiceId, page, size) => {
    const params = {
      questionnaireProtoId,
      questionId,
      choiceId,
      page,
      size
    }
    const result = await getAnalysisDetail(params)
    if (result.status === 200 && result.data?.code === '00000') {
      analysis.list = result.data.data.content
      analysis.current = Number(result.data.data.number)
      analysis.total = Number(result.data.data.totalElements)
      analysis.size = Number(result.data.data.size)
    }
  }
  getData(protoId, props.questionId, props.choiceId, pageNumber, pageSize)
  const { list, current, total, size } = toRefs(analysis)
  return { 
    list, 
    current, 
    total, 
    size 
  }
}

/**
 * 翻页操作
 */
const pageChangeEffectEffect = (props, questionnaireProtoId, list, current, size, total) => {
  const route = useRoute()
  const onPageChangeEvent = (pageNumber, pageSize) => {
    const data = getAnalysisDetailEffect(props, questionnaireProtoId, pageNumber, pageSize)
    list.value = data.list
    current.value = pageNumber
    size.value = pageSize
    total.value = data.total
  }
  return {
    onPageChangeEvent
  }
}

/**
 * 处理发布相关事件
 */
const handleEmitEffect = (emit) => {
  // 关闭模态窗
  const handleClose = () => { 
    emit('close') 
  }
  // 打开答卷
  // 暂时屏蔽此功能，需求不需要
  const handleOpenQuestionnaireWithAnswer = (questionnaireId) => {
    emit('openQuestionnaireWithAnswer', questionnaireId)
  }
  return {
    handleClose,
    handleOpenQuestionnaireWithAnswer,
  }
}

export default({
  name: 'AnalysisDetailModal',
  components: {
    Pager,
    RichTitle,
  },
  emits: [
    'close', 
    'openQuestionnaireWithAnswer'
  ],
  props: [
    'visible',  // 控制显示隐藏
    'questionId',
    'choiceId',
    'questionSort',
    'questionTitle',
    'choiceText',
  ],
  /**
   * 程序入口
   */
  setup(props, { emit }) {
    const route = useRoute()
    const questionnaireProtoId = route.params.id
    const pageNumber = 0
    const pageSize = 5
    const { list, current, total, size } = getAnalysisDetailEffect(props, questionnaireProtoId, pageNumber, pageSize)
    const { handleClose, handleOpenQuestionnaireWithAnswer } = handleEmitEffect(emit)
    const { onPageChangeEvent } = pageChangeEffectEffect(props, questionnaireProtoId, list, current, size, total)

    function handleExport() {
      let url = exportAnalyzeQuestionnaireText()
      // console.log("从api层获得的url", url);
      let params = {
        questionnaireProtoId: route.params.id || '',
        questionId: props.questionId || '',
        choiceId: props.choiceId || '',
      }
      let pairs = [];
      for (let name in params) {
        let value = params[name].toString();
        // encodeURIComponent()对URL中的键值对进行编码
        name = encodeURIComponent(name);
        value = encodeURIComponent(value);
        // 将转换好的键值对,存入容器中
        pairs.push(name + "=" + value);
      }
      const query = pairs.join("&"); // 将键值对之间用'&'拼接后返回
      url = url + "?" + query;
      window.open(url);
    }

    return {
      list, 
      current, 
      total,
      size,
      handleClose,
      handleOpenQuestionnaireWithAnswer,
      onPageChangeEvent,
      handleExport
    }
  },
})
</script>

<style lang='less'>
.titleDescModal {
  .title {
    text-align: left;
    font-weight: bold;
    div, p {
      display: inline-block;
    }
  }
  .second-line {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .choice-text {
    }
    .export {
      cursor: pointer;
    }
  }
  .content {
    margin-top: 20px;
    .center {
      text-align: center;
    }
    .num {
      color: #f59a23;
      text-decoration: underline;
      cursor: pointer;
    }
    .table-title {
      background: #f8f9fa;
      color: #49494a;
      border: 1px solid #eff2f5;
      padding: 8px 0;
    }
    .content-wrapper {
      height: 200px;
      overflow: auto;
      .table-row {
        border-bottom: 1px solid #eff2f5;
        padding: 8px 0;
      }
      
    }
  }
  ::selection {
    background: #fff!important;
  }
  .ant-modal-content {
    position: relative;
    border: 16px solid #eff2f5;
    border-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .ant-modal-header {
      border: none;
      .ant-modal-title {
        font-size: 20px;
      }
    }
    .ant-modal-body {
      .close {
        position: absolute;
        top: 8px;
        right: 24px;
        font-size: 20px;
        .pointer()
      }
      .ant-input-affix-wrapper {
        border: none;
        width: 70%;
        svg {
          font-size: 18px;
        }
      }
      input {
        border: none;
        border-bottom: 1px solid #d7d7d7;
      }
    }
  }
  .ant-modal-footer {
    border: none;
    display: none;
  }
}
</style>