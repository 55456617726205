<!--
 * @Description: 结果分析-矩阵量表
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-15 14:26:29
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-12 18:46:16
-->
<template>
  <div class="short-data-Aanalysis">
    <h1>{{item.sort + 1}}.<RichTitle :title="item.title"/></h1>
    <div class="button" @click="handleAnswerClick(item.questionId, item.title)">查看答案</div>
  </div>
</template>
<script>
import RichTitle from '@/components/question/components/richTitle'

export default {
  name: 'ShortOrDataAnalysis',
  components: {
    RichTitle,
  },
  props: ['item'],
  setup (props, { emit }) {
    const handleAnswerClick = (questionId, title) => {
        emit('showAnalysisDetailModal', questionId, null, title, null)
    }
    return {
      handleAnswerClick
    }
  }
}
</script>
<style lang='less' scoped>
.short-data-Aanalysis {
  margin-top: 32px;
  h1 {
    font-size: 16px;
    font-weight: bold;
    div, p {
      display: inline-block;
    }
  }
  .button {
    width: 76px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #fff;
    background: #f59a23;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    margin-top: 24px;
    cursor: pointer;
    &:hover {
      background-color: #f59a23d7;
    }
  }
}
</style>